import { useEffect } from 'react'
import axios from 'axios'
import { ENV } from '../utils/env'

export default function EdenredPostLogin() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params.code && params.state) {
      const edenredAuthentication = async () => {
        const sourceAuth = await axios.get(
          `${ENV.API_PAYMENT_URL}/v1/edenred/customers/login?code=${params.code}&state=${params.state}`,
        )
        const sourceAuthUrl = new URL(sourceAuth.data.redirectUrl)
        sourceAuthUrl.searchParams.set('edenredAuth', `${sourceAuth.data.status === 'succeeded'}`)
        sourceAuthUrl.searchParams.set('access_token', sourceAuth.data.accessToken)
        window.location.replace(sourceAuthUrl.href)
      }

      edenredAuthentication()
    }
  }, [])

  return <div id="action"></div>
}
