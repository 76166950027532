export const assertUnreachable = (x: never): never => {
  throw new Error(`assertUnreachable called with ${x}`)
}

export const enumKeys = (theEnum: { [key: string]: unknown }): string[] =>
  Object.keys(theEnum).filter(key => Number.isNaN(Number(key)))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumValues = <T>(theEnum: { [key: string]: T } | any): T[] =>
  enumKeys(theEnum).map<T>(key => (theEnum as { [key: string]: T })[key])

export function throwIfFalsy<T>(x: T | null | undefined, message = 'This should never happen'): asserts x is T {
  if (!x) {
    throw new Error(message)
  }
}

export type Only<T, U> = {
  [P in keyof T]: T[P]
} & {
  [P in keyof U]?: never
}

export type Either<T, U> = Only<T, U> | Only<U, T>

export type TwoLevelPartial<T> = {
  [P in keyof T]?: Partial<T[P]>
}

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]
