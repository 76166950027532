import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import AdyenAction from './pages/AdyenAction'
import WelcomeBack from './pages/WelcomeBack'
import NotFound from './pages/NotFound'
import WelcomeBackFromPaygreen from './pages/WelcomeBackFromPaygreen'
import EdenredPostLogin from './pages/EdenredPostLogin'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Router>
    <Routes>
      <Route path="/edenred/post-login" element={<EdenredPostLogin />} />
      <Route path="/adyen-action" element={<AdyenAction />} />
      <Route path="/welcome-back" element={<WelcomeBack />} />
      <Route path="/welcome-back-from-paygreen" element={<WelcomeBackFromPaygreen />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  </Router>,
)
