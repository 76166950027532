import { useLayoutEffect } from 'react'
import AdyenCheckout from '@adyen/adyen-web'

export default function AdyenAction() {
  useLayoutEffect(() => {
    window.addEventListener('message', async event => {
      if (event.data && event.data.messageType === 'action') {
        const checkout = await AdyenCheckout(event.data.configuration)
        const action = checkout.createFromAction(event.data.action)
        action.mount('#action')
      }
    })
  }, [])

  return <div id="action"></div>
}
