import { useEffect } from 'react'

export default function WelcomeBack() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    window.parent.postMessage(
      { messageType: 'authentication-completed', resultCode: '3ds', result: params.redirectResult },
      '*',
    )
  }, [])

  return <div id="action"></div>
}
