import { useEffect } from 'react'

export default function WelcomeBackFromPaygreen() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params.return) {
      window.parent.postMessage(
        {
          id: 'paygreen-insite',
          message: {
            pid: params.po_id,
            result: params.status,
            orderId: params.orderId,
          },
        },
        '*',
      )
    } else if (params.cancel) {
      window.parent.postMessage(
        {
          id: 'close-modal',
        },
        '*',
      )
    }
  }, [])

  return <div id="action"></div>
}
